import './index.css'
import Logo from '../../Assets/H.svg'

const NavBar = () => {
    return (
        <>
            <div className="nav_bar">
                <img className="logo" src={Logo} alt='logo' />

                <div className='contact_container'>
                    <div className='contact_content'>
                        Phone: 4379876631
                    </div>
                    <div className='contact_content'>
                        Email: profgarden69@gmail.com
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavBar;