import './index.css'
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";

const EstimateForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const refForm = useRef();
    console.log('yes')
    const onFinish = (e) => {
        e.preventDefault();
        const data = {
            service_id: 'service_o9lnh62',
            template_id: 'template_vwvt3oa',
            user_id: 'fMIMfc_ofFYA47Go2',
            template_params: { ...formData }
        }

        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        }).then(
                (r) => {
                    console.log('success', r)
                    alert('success')
                },
                (error) => {
                    alert(error)
                    console.log('Failed to send the message, please try again', error)
                }
            )
    };

    return (
        <form className='contact_form' ref={refForm} onSubmit={onFinish}>
            <div className='form_list'>
                <input
                    id='name'
                    className='input_name'
                    type='text'
                    name='name'
                    placeholder='Name'
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <input
                    id='email'
                    className='input_email'
                    type='email'
                    name='email'
                    placeholder='Email'
                    value={formData.email}
                    onChange={handleChange}
                    required
                />

                <input
                    id='phone'
                    className='input_phone'
                    type='phoneNum'
                    name='phone'
                    placeholder='Phone Number'
                    value={formData.phone}
                    onChange={handleChange}
                    required
                />

                <textarea
                    id='message'
                    className='input_message'
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                />
                <input type='submit' className='flat-button' value='Free Estimate' />
            </div>
        </form>
    )
}

export default EstimateForm;