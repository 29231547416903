import './index.css'
import Logo from '../../Assets/H.svg'

const Footer = () => {

    return (
        <div className='footer'>
            <div className='footer_container'>
                <div className='right_container'>

                    <img className='logo' src={Logo} alt='logo' />
                    <div className='right_text'>@Garden Prof 2023. All rights reserved</div>
                </div>
                <div className='contact_container'>
                    <div className='contact_content'>
                        Phone: 4379876631
                    </div>
                    <div className='contact_content'>
                        Email: profgarden69@gmail.com
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer;
