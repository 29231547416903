import './index.css'
import Img1 from '../../Assets/about/f1.jpg'
import Img2 from '../../Assets/about/f2.jpg'
import Img3 from '../../Assets/about/f3.jpg'
import Img4 from '../../Assets/about/g1.jpg'
import Img5 from '../../Assets/about/g2.jpg'
import Img6 from '../../Assets/about/i1.jpg'
import Img7 from '../../Assets/about/i2.jpg'
import Img8 from '../../Assets/about/i3.jpg'
import Img9 from '../../Assets/about/i4.jpg'
import Img10 from '../../Assets/about/s1.jpg'
import Img11 from '../../Assets/about/s2.jpg'
import FadeInLeft from "../FadeInLeft";
import FadeInRight from "../FadeInRight";

const About = () => {


    return (
        <div id='about' className='about'>
            <div className='gallery'>
                <div className='fade_text_I'>
                    <FadeInLeft>
                        <div className='service_name'>Interlocking</div>
                    </FadeInLeft>
                </div>
                <img className='work_img' src={Img6} alt='img' />
                <img className='work_img' src={Img7} alt='img' />
                <img className='work_img' src={Img8} alt='img' />
                <img className='work_img' src={Img9} alt='img' />
            </div>

            <div className='gallery'>
                <div className='fade_text_F'>
                    <FadeInRight>
                        <div className='service_name'>Fencing</div>
                    </FadeInRight>
                </div>
                <img className='work_img' src={Img2} alt='img' />
                <img className='work_img' src={Img3} alt='img' />
            </div>
            <div className='gallery'>
                <div className='fade_text_G'>
                    <FadeInRight>
                        <div className='service_name'>Gardening</div>
                    </FadeInRight>
                </div>
                <img className='work_img' src={Img4} alt='img' />
                <img className='work_img' src={Img5} alt='img' />
            </div>
            <div className='gallery'>
                <div className='fade_text_S'>
                    <FadeInLeft>
                        <div className='service_name'>Stairs</div>
                    </FadeInLeft>
                </div>
                <img className='work_img' src={Img10} alt='img' />
                <img className='work_img' src={Img11} alt='img' />
                <img className='work_img' src={Img1} alt='img' />
            </div>

        </div>


    )

};

export default About;