import './index.css'
import estimate_bg from "../../Assets/estimate_bg_crop.jpg"
import EstimateForm from "./EstimateForm";
const Estimate = () => {

    return (
        <div className='estimate_container'>
            {/*<img className='estimate_bg' src={estimate_bg} alt='estimate background' />*/}
            <div className='estimate_text'>
                <div className='title'> Professional Landscaping Inc </div>
                <div className='caption'> Interlocking | Fencing | Deck </div>
            </div>
            <div className='estimate_form' >
                <EstimateForm />
            </div>
        </div>
    )
}

export default Estimate
