import React from 'react';
import { useInView } from 'react-intersection-observer';
import './index.scss'; // Import the external CSS file

interface FadeInOutProps {
    children: React.ReactNode;
}

const FadeInLeft: React.FC<FadeInOutProps> = ({ children }) => {
    const { ref, inView } = useInView({
        threshold: 0.8, // Adjust this value as needed
    });

    return (
        <div ref={ref} className={`fade-in-left ${inView ? 'visible' : ''}`}>
            {children}
        </div>
    );
};

export default FadeInLeft;
