import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Components/Home';

function App() {
  return (
    <div className='app'>
        <Home />

    </div>
  );
}

export default App;
