import './index.css'
import NavBar from '../Navbar';
import About from '../About';
import Team from '../Team';
import Contact from '../Contact';
import Estimate from "../Estimate";
import Footer from "../Footer";
const Home = () => {
    return <>
        <div className='home'>
            <NavBar/>
            <Estimate />
            <About/>
            <Footer />
        </div>
    </>;
}

export default Home;